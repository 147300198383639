





































































import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";
import { UserAnalyticsStatsModel, DateValue } from "@/core/models";

import AppVue from "@/AppVue.vue";
import moment from "moment";
import { fromMinuteHumanReadable, numberFormatterFilter } from "@/core/filters";
import ChartCpt from "./ChartCpt.vue";

export class Type {}

@Component({
  components: {
    ChartCpt: () => Promise.resolve(ChartCpt),
  },
})
export default class UserChartsCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: "bar" }) selectedChart: string;
  @Prop({ default: "User statistic" }) downloadName: string;
  @Prop({ default: "MMM Do YY" }) dateFormat: string;
  @Prop({ default: {} as UserAnalyticsStatsModel }) userStats: UserAnalyticsStatsModel;

  chartOptions = {
    colors: ["#0097b7", "#fb6d13"],
    legend: {
      position: "top",
      show: true,
    },
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
        export: {
          csv: {
            filename: "",
          },
          svg: {
            filename: "",
          },
          png: {
            filename: "",
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      dropShadow: {
        enabled: true,
        left: 2,
        top: 2,
        opacity: 0.5,
      },
      style: {
        fontSize: "12px",
        fontWeight: "bold",
      },
      position: "bottom",
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      type: "category",
      tickAmount: 12,
    },
  };
  chartMinutesOptions = {
    ...this.chartOptions,
    yaxis: {
      labels: {
        formatter: (value: number) => {
          return fromMinuteHumanReadable(value);
        },
      },
    },
  };
  get isLoading() {
    return this.loading;
  }

  transformSerie(rows: DateValue[]) {
    if (!rows) {
      return [];
    }
    const res = rows.map((r) => ({
      x: moment.utc(r.date).format(this.dateFormat),
      y: r.value,
    }));
    return res;
  }

  async created() {}

  async mounted() {
    this.chartOptions.chart.toolbar.export.csv.filename = this.downloadName;
    this.chartOptions.chart.toolbar.export.png.filename = this.downloadName;
    this.chartOptions.chart.toolbar.export.svg.filename = this.downloadName;
  }
}
