var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',[_c('ChartCpt',{attrs:{"data":[{name:'Sessions',data:this.transformSerie(_vm.userStats.sessions)}],"md":12,"selectedChart":_vm.selectedChart,"t":"Number of Sessions","options":_vm.chartOptions}}),_c('ChartCpt',{attrs:{"data":[{name:'Minutes online',data:this.transformSerie(_vm.userStats.sessionLength)}],"md":12,"selectedChart":_vm.selectedChart,"t":"Minutes online","options":_vm.chartMinutesOptions}})],1),_c('el-row',[_c('ChartCpt',{attrs:{"data":[
                   {name:'Project search',data:this.transformSerie(_vm.userStats.projectSearchInteractions)},
                   {name:'Company search',data:this.transformSerie(_vm.userStats.companySearchInteractions)} ],"md":12,"selectedChart":_vm.selectedChart,"t":"Searches","options":_vm.chartOptions}}),_c('ChartCpt',{attrs:{"data":[
                   {name:'Projects tracked',data:this.transformSerie(_vm.userStats.projectTrackedInteractions)},
                   {name:'Companies tracked',data:this.transformSerie(_vm.userStats.companyTrackedInteractions)} ],"md":12,"selectedChart":_vm.selectedChart,"t":"Tracked","options":_vm.chartOptions}})],1),_c('el-row',[_c('ChartCpt',{attrs:{"data":[
                   {name:'Project details',data:this.transformSerie(_vm.userStats.projectInteractions)},
                   {name:'Company details',data:this.transformSerie(_vm.userStats.companyInteractions)} ],"md":12,"selectedChart":_vm.selectedChart,"t":"Views","options":_vm.chartOptions}}),_c('ChartCpt',{attrs:{"data":[
                   {name:'Project peek',data:this.transformSerie(_vm.userStats.projectPeekInteractions)},
                   {name:'Company peek',data:this.transformSerie(_vm.userStats.companyPeekInteractions)} ],"md":12,"selectedChart":_vm.selectedChart,"t":"Peeks","options":_vm.chartOptions}})],1),_c('el-row',[_c('ChartCpt',{attrs:{"data":[
                   {name:'Project exports',data:this.transformSerie(_vm.userStats.projectExports)},
                   {name:'Company exports',data:this.transformSerie(_vm.userStats.companyExports)} ],"md":12,"selectedChart":_vm.selectedChart,"t":"Exports","options":_vm.chartOptions}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }