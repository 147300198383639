











import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class ChartCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: 12 }) md: number;
  @Prop({ default: "Chart" }) t: string;
  @Prop({ default: "false" }) selectedChart: string;
  @Prop({ default: {} }) options: any;
  @Prop({ default: () => [] }) data: any[];

  get isLoading() {
    return this.loading;
  }
  get series() {
    return this.data;
  }

  async created() {}

  async mounted() {}
}
